export class Calculator{
action(){
	const cal = document.querySelector('form#slabCalculator');

	const buttons =[...cal.getElementsByTagName('button')];
	let fields = [
		...cal.getElementsByTagName('input'),
		...cal.getElementsByTagName('select'),		
	];
	fields.forEach(field=>{
		field.addEventListener('change',(e)=>{
			this.form_check();
		})
	});
		cal.addEventListener('click',(e)=>{
			const reset = e.target.closest('[type="reset"]');
			const submit = e.target.closest('[type="button"]')
			
			if (submit) {
				this.form_check();
				this.calculate(0)
			}

			if (reset) {				
				this.reset_form();
			}
	})

}
calculateLogic() {
	$('#slabCalculator').on('change', 'input', () => {
		this.calcutor(0);
	})
	$('#slabCalculator').on('change', 'select', () => {
		this.calcutor(0);
	})
	$('#slabCalculator').on('click', '.reset', () => {
		this.reset_form();
	});
	$('#slabCalculator').on('click', '.submit', () => {
		this.calcutor(1);
	});
}
reset_form() {
	document.getElementById('area_display').innerHTML = '&nbsp';
	document.getElementById('result_display').innerHTML = '&nbsp';
	let fields = [
		...cal.getElementsByTagName('input'),
		...cal.getElementsByTagName('select'),		
	];
	fields.forEach(field=>{
		// field.addEventListener('change',(e)=>{
		// 	this.form_check();
		// })
		field.value = '';
	});
}
form_check() {

	if (document.getElementById('calc_length').value == '') {
		alert('กรุณากรอกความยาว.');
		document.getElementById('calc_length').focus();
		return false;
	}

	if (isNaN(document.getElementById('calc_length').value)) {
		alert('กรอกตัวเลขที่ต้องการ');
		document.getElementById('calc_length').focus();
		return false;
	}

	if (document.getElementById('calc_width').value == '') {
		alert('กรุณากรอกความกว้าง');
		document.getElementById('calc_width').focus();
		return false;
	}

	if (isNaN(document.getElementById('calc_width').value)) {
		alert('กรอกตัวเลขที่ต้องการ');
		document.getElementById('calc_width').focus();
		return false;
	}

	if (document.getElementById('calc_depth').value == '') {
		alert('กรุณากรอกความหนาตามต้องการ');
		document.getElementById('calc_depth').focus();
		return false;
	}

	if (isNaN(document.getElementById('calc_depth').value)) {
		alert('กรอกตัวเลขที่ต้องการ');
		document.getElementById('calc_depth').focus();
		return false;
	}

	if (document.getElementById('agree_terms').checked == false) {
		alert('กรุณาติ๊กช่องยอมรับเงื่อนไขด้วย');
		return false;
	}
}

calculate(do_form_check) {
	let length = document.getElementById('calc_length').value;
	let width = document.getElementById('calc_width').value;
	let calc_area = '';
	let depth = document.getElementById('calc_depth').value;
	let length_units = document.getElementById('length_units').value;
	let width_units = document.getElementById('width_units').value;
	let depth_units = document.getElementById('depth_units').value;
	let multiplier = 1;

	if (length != '' && !isNaN(length) && width != '' && !isNaN(width)) {
		length = this.convert_to_metres(length, length_units);
		width = this.convert_to_metres(width, width_units);

		calc_area = length * width;
	}
	document.getElementById('area_display').innerHTML = (calc_area == '' ? '&nbsp;' : calc_area);

	if (calc_area != '' && depth != '' && !isNaN(depth) && document.getElementById('agree_terms').checked == true) {
		depth = this.convert_to_metres(depth, depth_units);

		let calc_result = calc_area * depth;
		const msg = 'ปูนที่ต้องใช้ (คิว) : ';

		calc_result = calc_result.toFixed(1);
		const bagmsg = 'Bulk Bags Required : ';

		document.getElementById('area_display').innerHTML = calc_area;
		document.getElementById('result_display').innerHTML = msg + calc_result;
		document.getElementById('metres_req').value = calc_result;

	} else if (do_form_check == 1) {
		return form_check();
	}
}
calcutor(do_form_check) {
	let length = document.getElementById('calc_length').value;
	let width = document.getElementById('calc_width').value;
	let calc_area = '';
	let depth = document.getElementById('calc_depth').value;
	let length_units = document.getElementById('length_units').value;
	let width_units = document.getElementById('width_units').value;
	let depth_units = document.getElementById('depth_units').value;
	let multiplier = 1;

	if (length != '' && !isNaN(length) && width != '' && !isNaN(width)) {
		length = this.convert_to_metres(length, length_units);
		width = this.convert_to_metres(width, width_units);

		calc_area = length * width;
	}
	document.getElementById('area_display').innerHTML = (calc_area == '' ? '&nbsp;' : '<strong>จำนวน</strong>&nbsp;' + calc_area + '&nbsp;<strong>ตร.ม.(m<sup>2</sup>)</strong>');

	if (calc_area != '' && depth != '' && !isNaN(depth) && document.getElementById('agree_terms').checked == true) {
		depth = this.convert_to_metres(depth, depth_units);

		let calc_result = calc_area * depth;
		let msg = 'ปูนที่ต้องใช้ (คิว) : ';

		calc_result = calc_result.toFixed(1);
		let bagmsg = 'Bulk Bags Required : ';

		document.getElementById('area_display').innerHTML = '<strong>จำนวน</strong>&nbsp;' + calc_area + '&nbsp;<strong>ตร.ม.(m<sup>2</sup>)</strong>';
		document.getElementById('result_display').innerHTML = msg + calc_result;

	} else if (do_form_check == 1) {
		return this.form_check();
	}
}
reset_form() {
	const cal = document.querySelector('form#slabCalculator');
	document.getElementById('area_display').innerHTML = '&nbsp';
	document.getElementById('result_display').innerHTML = '&nbsp';
	// document.getElementById('metres_req').value = '';
	let fields = [
		...cal.getElementsByTagName('input'),
		...cal.getElementsByTagName('select'),		
	];
	fields.forEach(field=>{
		// field.addEventListener('change',(e)=>{
		// 	this.form_check();
		// })
		field.value = '';
	});
}

convert_to_metres(value, units) {
	var multiplier = 1;

	switch (units) {
		case 'mm':
			multiplier = 0.001;
			break;
		case 'cm':
			multiplier = 0.01;
			break;
		case 'km':
			multiplier = 1000;
			break;
		case 'inches':
			multiplier = 0.0254;
			break;
		case 'feet':
			multiplier = 0.3048;
			break;
		case 'yards':
			multiplier = 0.9144;
			break;
		case 'miles':
			multiplier = 1609;
			break;
	}

	return value * multiplier;
}
}
